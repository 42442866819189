import { Semester, SemesterUI, StarDateUI } from "./StartDateField";

export const getSemesterUIList = (semesters: Semester[]): SemesterUI[] => {
  const semesterUIList: SemesterUI[] = [];
  const semestersUnique = new Set<string>();

  for (let i = 0; i < semesters.length; i++) {
    const semester = semesters[i];
    semestersUnique.add(`${semester.semesterCode}-${semester.semesterName}`);
  }

  const semestersUniqueArr = Array.from(semestersUnique);
  for (let i = 0; i < semestersUniqueArr.length; i++) {
    const semester = semestersUniqueArr[i];
    const [code, name] = semester.split("-");
    const startDates = semesters
      .filter((semester) => semester.semesterCode === code)
      .map((semester) => ({
        startDate: semester.sessionStartDate,
        endDate: semester.sessionEndDate,
        semesterStartDate: semester.semesterStartDate,
        semesterEndDate: semester.semesterEndDate,
        isSelected: false,
      }))
      .sort(
        (a, b) =>
          new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
      );

    let startDatesUnique = startDates.reduce(
      (acc, curr) => ({ ...acc, [curr.startDate]: curr }),
      {}
    );
    startDatesUnique = Object.values(startDatesUnique);

    semesterUIList.push({
      semesterCode: code,
      semesterName: name,
      startDates: startDatesUnique as StarDateUI[],
      isSelected: i === 0,
      fromDate: startDates[0].startDate,
      toDate: startDates[startDates.length - 1].endDate,
      semesterStartDate: startDates[0].semesterStartDate,
      semesterEndDate: startDates[0].semesterEndDate,
    });
  }

  return semesterUIList;
};

export interface InterStarDateUI {
  isSelected: boolean;
  startDate: string;
}
export interface InterMonthUI {
  name: string;
  isSelected: boolean;
  startDates: InterStarDateUI[];
}

export interface InterClassFormatUI {
  name: string;
  months: InterMonthUI[];
}
export interface InterSemesterUI {
  semesterCode: string;
  semesterName: string;
  classFormats: InterClassFormatUI[];
  isSelected: boolean;
  fromDate: string;
  toDate: string;
  semesterStartDate: string;
  semesterEndDate: string;
}

export interface InterSemester {
  semesterCode: string;
  semesterName: string;
  sessionStartDate: string;
  sessionEndDate: string;
  semesterStartDate: string;
  semesterEndDate: string;
  classStartDate: string;
  classFormatCode: string;
}

const onsiteVirtualFormats = [
  "HY",
  "P",
  "LH",
  "HT",
  "PT",
  "LP",
  "LT",
  "FS",
  "HZ",
  "VO",
  "VT",
];

export const getInterSemesterUIList = (
  semesters: InterSemester[]
): InterSemesterUI[] => {
  const semesterUIList: InterSemesterUI[] = [];

  // SEMESTER LEVEL
  const semestersUnique = new Set<string>();

  for (let i = 0; i < semesters.length; i++) {
    const semester = semesters[i];
    semestersUnique.add(`${semester.semesterCode}-${semester.semesterName}`);
  }
  const semestersUniqueArr = Array.from(semestersUnique);

  // CLASS FORMAT BUCKET LEVEL
  const classFormatUnique = new Set<string>();
  for (let i = 0; i < semesters.length; i++) {
    const semester = semesters[i];
    if (semester.classFormatCode === "WT") {
      classFormatUnique.add("Online");
    } else if (onsiteVirtualFormats.includes(semester.classFormatCode)) {
      classFormatUnique.add("Onsite/Virtual");
    }
  }
  const classFormatUniqueArr = Array.from(classFormatUnique);

  // MONTH LEVEL
  const monthUnique = new Set<string>();
  for (let i = 0; i < semesters.length; i++) {
    const semester = semesters[i];
    const [, month] = semester.classStartDate.split("-");
    monthUnique.add(month);
  }
  const monthUniqueArr = Array.from(monthUnique);

  // CLASS START DATE LEVEL
  const classStartDateUnique = new Set<string>();
  for (let i = 0; i < semesters.length; i++) {
    const semester = semesters[i];
    classStartDateUnique.add(semester.classStartDate);
  }
  const classStartDateUniqueArr = Array.from(classStartDateUnique);

  // SEMESTER LEVEL
  for (let i = 0; i < semestersUniqueArr.length; i++) {
    const semester = semestersUniqueArr[i];

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [code, name] = semester.split("-");
    const startDates = semesters
      .filter((semester) => semester.semesterCode === code)
      .map((semester) => ({
        startDate: semester.sessionStartDate,
        endDate: semester.sessionEndDate,
        semesterStartDate: semester.semesterStartDate,
        semesterEndDate: semester.semesterEndDate,
        isSelected: false,
      }))
      .sort(
        (a, b) =>
          new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
      );

    const semesterUIItem = {
      semesterCode: semester,
      semesterName: semester,
      classFormats: [] as InterClassFormatUI[],
      isSelected: i === 0,
      fromDate: startDates[0].startDate,
      toDate: startDates[startDates.length - 1].endDate,
      semesterStartDate: startDates[0].semesterStartDate,
      semesterEndDate: startDates[0].semesterEndDate,
    };
    semesterUIList.push(semesterUIItem);

    // CLASS FORMAT LEVEL
    for (let j = 0; j < classFormatUniqueArr.length; j++) {
      const classFormat = classFormatUniqueArr[j];
      const count = semesters
        .filter((item) => item.semesterCode === code)
        .filter((item) => {
          if (classFormat === "Online") {
            return item.classFormatCode === "WT";
          } else if (classFormat === "Onsite/Virtual") {
            return onsiteVirtualFormats.includes(item.classFormatCode);
          }
        }).length;
      if (count === 0) continue;

      const interClassFormatUI: InterClassFormatUI = {
        months: [],
        name: classFormat,
      };
      semesterUIItem.classFormats.push(interClassFormatUI);

      // MONTH LEVEL
      for (let k = 0; k < monthUniqueArr.length; k++) {
        const month = monthUniqueArr[k];
        const count = semesters
          .filter((item) => item.semesterCode === code)
          .filter((item) => {
            if (classFormat === "Online") {
              return item.classFormatCode === "WT";
            } else if (classFormat === "Onsite/Virtual") {
              return onsiteVirtualFormats.includes(item.classFormatCode);
            }
          })
          .filter((item) => {
            const [, monthItem] = item.classStartDate.split("-");
            return monthItem === month;
          }).length;
        if (count === 0) continue;

        const interMonthUI: InterMonthUI = {
          isSelected: false,
          startDates: [],
          name: month,
        };
        interClassFormatUI.months.push(interMonthUI);

        // CLASS START DATE LEVEL
        for (let w = 0; w < classStartDateUniqueArr.length; w++) {
          const classStartDate = classStartDateUniqueArr[w];
          const count = semesters
            .filter((item) => item.semesterCode === code)
            .filter((item) => {
              if (classFormat === "Online") {
                return item.classFormatCode === "WT";
              } else if (classFormat === "Onsite/Virtual") {
                return onsiteVirtualFormats.includes(item.classFormatCode);
              }
            })
            .filter((item) => {
              const [, monthItem] = item.classStartDate.split("-");
              return monthItem === month;
            })
            .filter((item) => item.classStartDate === classStartDate).length;
          if (count === 0) continue;

          const interStarDateUI: InterStarDateUI = {
            startDate: classStartDate,
            isSelected: false,
          };
          interMonthUI.startDates.push(interStarDateUI);
        }
      }
    }
  }

  return semesterUIList;
};
