import clsx from "clsx";
import { DateTime } from "luxon";
import { ClassSearchContext } from "pages/newClassSearch/classSearchContext";
import React, { useContext, useRef } from "react";
import { useOnClickOutside } from "usehooks-ts";

import InterStartDateList from "./InterStartDateList";
import { InterSemesterUI } from "./shared";

interface SemesterMenuProps {
  setIsMenuOpen: (value: boolean) => void;
  semesterUIList: InterSemesterUI[];
  setSemesterUIList: React.Dispatch<React.SetStateAction<InterSemesterUI[]>>;
}

const InterSemesterMenu: React.FC<SemesterMenuProps> = ({
  setIsMenuOpen,
  semesterUIList,
  setSemesterUIList,
}) => {
  const { dispatch } = useContext(ClassSearchContext);
  const divRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(divRef, () => {
    setIsMenuOpen(false);
  });

  // Find the selected semester
  const selectedSemester = semesterUIList.find((item) => item.isSelected);

  const handleSelectAll = () => {
    if (!selectedSemester) return;

    // Update the `isSelected` state for all start dates and months
    setSemesterUIList((prev) =>
      prev.map((semester) =>
        semester.semesterCode === selectedSemester.semesterCode
          ? {
            ...semester,
            classFormats: semester.classFormats.map((classFormat) => ({
              ...classFormat,
              months: classFormat.months.map((month) => ({
                ...month,
                isSelected: true, // Mark the entire month as selected
                // startDates: month.startDates.map((startDate) => ({
                //   ...startDate,
                //   isSelected: false,
                // })),
              })),
            })),
          }
          : semester
      )
    );

    // Extract and deduplicate the selected start dates
    const updatedStartDateList = Array.from(
      new Set(
        selectedSemester.classFormats.flatMap((classFormat) =>
          classFormat.months
            .filter((month) => month.isSelected) // Only include selected months
            .flatMap((month) => month.startDates.map((startDate) => startDate.startDate))
        )
      )
    );

    // Dispatch the updated start dates to the context
    dispatch({
      type: "UPDATE_CLASS_START_DATE_LIST",
      payload: { classStartDateList: updatedStartDateList },
    });
  };

  return (
    <div ref={divRef} className="relative z-[1]">
      <div className="absolute flex min-h-[150px] w-auto flex-col overflow-hidden rounded-md border bg-white shadow-md sm:w-[470px] sm:flex-row">
        {/* Terms List */}
        <div className="w-auto sm:w-[55%]">
          <p className="cursor-pointer px-4 py-2 font-bold text-[#5187BF]">Terms</p>
          {semesterUIList.length === 0 ? (
            <div className="px-4 py-2">
              There are currently no scheduled courses for your program. Please contact an advisor for assistance.
            </div>
          ) : (
            <ul role="tablist">
              {semesterUIList.map((item) => (
                <li
                  key={item.semesterCode}
                  role="presentation"
                  className={clsx(
                    "cursor-pointer select-none !py-2 px-4",
                    item.isSelected && "bg-[#FEFAEB]"
                  )}
                >
                  <button
                    role="tab"
                    aria-selected={item.isSelected ? "true" : "false"}
                    aria-controls={`tabpanel-${item.semesterCode}`}
                    id={`tab-${item.semesterCode}`}
                    className="w-full text-left"
                    onClick={() => {
                      setSemesterUIList((prev) =>
                        prev.map((semesterUI) => ({
                          ...semesterUI,
                          isSelected: semesterUI.semesterCode === item.semesterCode,
                        }))
                      );
                    }}
                  >
                    <p className="font-bold">{item.semesterName.split("-").slice(1).join("-")}</p>
                    <p className="font-semibold">
                      {DateTime.fromISO(item.semesterStartDate).toFormat("MMMM d")} -{" "}
                      {DateTime.fromISO(item.semesterEndDate).toFormat("MMMM d")}
                    </p>
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>

        {/* Start Dates for Selected Term */}
        <div
          className="w-auto bg-[#FEFAEB] sm:w-[45%]"
          role="tabpanel"
          id={`tabpanel-${selectedSemester?.semesterCode}`}
          aria-labelledby={`tab-${selectedSemester?.semesterCode}`}
        >
          <p className="px-4 py-2 pb-0 font-bold text-[#5187BF]">Start Dates</p>
          <button
            id="cs-start-dates-select-all"
            className={clsx(
              "px-4 text-sm text-[#5187BF] hover:underline",
              semesterUIList.length === 0 && "hidden"
            )}
            onClick={handleSelectAll}
          >
            Select All
          </button>
          <InterStartDateList
            semesterUIList={semesterUIList}
            setSemesterUIList={setSemesterUIList}
          />
        </div>
      </div>
    </div>
  );
};

export default InterSemesterMenu;
