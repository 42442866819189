import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import Check from "icons/Check";
import React from "react";

interface CheckboxAltProps {
  id?: string;
  value: boolean;
  ariaLabelledby?: string;
  onChange: (value: boolean) => void;
}

const CheckboxAlt: React.FC<CheckboxAltProps> = ({
  id,
  value,
  ariaLabelledby,
  onChange,
}) => {
  return (
    <CheckboxPrimitive.Root
      id={id}
      aria-labelledby={ariaLabelledby}
      value={value ? "on" : "off"}
      checked={value}
      onCheckedChange={(newValue) => {
        if (typeof newValue === "boolean") {
          onChange(newValue);
        }
      }}
      className="flex h-[20px] min-h-[20px] min-w-[20px] items-center justify-center rounded-[3px] border border-solid border-du-black data-[state=unchecked]:border-du-black data-[state=checked]:bg-du-yellow data-[sate=unchecked]:w-[20px] data-[state=unchecked]:bg-white"
    >
      <CheckboxPrimitive.Indicator>
        <Check
          className="h-[15px] w-[15px] data-[state=checked]:block data-[state=unchecked]:hidden"
          fillColor="#3c3c43"
        />
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
  );
};

export default CheckboxAlt;
